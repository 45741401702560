<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
});
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>