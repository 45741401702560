import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw} from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path:'/',
    redirect:'/user',
  },
  {
    path:'/user',
    name:'User',
    meta:{ title:'项目中心'},
    component: ()=> import('@/views/System/user/user.vue')
  },
  {
    path:'/project',
    name:'Project',
    meta:{ title:'首页'},
    component: ()=> import('@/views/index.vue')
  },
  {
    path:'/login',
    name:'Login',
    meta:{ title:'登陆'},
    component: ()=> import('@/views/System/user/login.vue')
  },
  {
    path:'/register',
    name:'Register',
    meta:{ title:'注册'},
    component: ()=> import('@/views/System/user/register.vue')
  },
  {
    path:'/forgotPassword',
    name:'ForgotPassword',
    meta:{ title:'忘记密码'},
    component: ()=> import('@/views/System/user/forgotPassword.vue')
  },
  {
    path:'/resetPassword',
    name:'ResetPassword',
    meta:{ title:'忘记密码'},
    component: ()=> import('@/views/System/user/resetPassword.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/System/404.vue')
  },
  {
    path:'/share',
    name:'Share',
    meta:{title:'分享'},
    component:()=>import('@/views/System/share/index.vue')
  }
]
const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export default router