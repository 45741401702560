import { Slide } from '@/types/slides'

export const layouts: Slide[] = [
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'dTwwDl',
  //       left: 104.12962962962959,
  //       top: 213.75000000000003,
  //       width: 791.7407407407408,
  //       height: 116,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'font-size: 80px\'>在此处添加标题</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       wordSpace: 6,
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'idn7Mx',
  //       left: 145,
  //       top: 148,
  //       width: 711,
  //       height: 77,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //     {
  //       type: 'text',
  //       id: '7stmVP',
  //       left: 207.50000000000003,
  //       top: 249.84259259259264,
  //       width: 585,
  //       height: 56,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处添加副标题</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'Ri5fok',
  //       left: 45,
  //       top: 40,
  //       width: 711,
  //       height: 77,
  //       lineHeight: 1.2,
  //       content: '<p style=\'\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //     {
  //       type: 'text',
  //       id: '57jpaB',
  //       left: 45,
  //       top: 150,
  //       width: 585,
  //       height: 56,
  //       content: '<p style=\'\'><span style=\'font-size: 24px\'>在此处添加副标题</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'xEykLD',
  //       left: 45,
  //       top: 355,
  //       width: 711,
  //       height: 77,
  //       lineHeight: 1.2,
  //       content: '<p><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //     {
  //       type: 'text',
  //       id: '4lvQ5n',
  //       left: 45,
  //       top: 460,
  //       width: 585,
  //       height: 56,
  //       content: '<p><span style=\'font-size: 24px\'>在此处添加副标题</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'shape',
  //       id: '4cbRxp',
  //       left: 0,
  //       top: 200,
  //       width: 546,
  //       height: 362.5,
  //       viewBox: 200,
  //       path: 'M 0 0 L 0 200 L 200 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       opacity: 0.7,
  //       rotate: 0
  //     },
  //     {
  //       type: 'shape',
  //       id: 'ookHrf',
  //       left: 0,
  //       top: 0,
  //       width: 300,
  //       height: 320,
  //       viewBox: 200,
  //       path: 'M 0 0 L 0 200 L 200 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       flipH: true,
  //       rotate: 0
  //     },
  //     {
  //       type: 'text',
  //       id: 'AkIh3E',
  //       left: 355,
  //       top: 95.11111111111111,
  //       width: 585,
  //       height: 116,
  //       lineHeight: 1.2,
  //       content: '<p style=\'\'><strong><span style=\'font-size: 80px\'>输入标题</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       wordSpace: 6
  //     },
  //     {
  //       type: 'text',
  //       id: '7stmVP',
  //       left: 355,
  //       top: 253.25,
  //       width: 585,
  //       height: 56,
  //       content: '<p><span style=\'font-size:  24px\'>请在此处输入副标题</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333'
  //     },
  //     {
  //       type: 'line',
  //       id: 'FnpZs4',
  //       left: 361,
  //       top: 238,
  //       start: [0, 0],
  //       end: [549, 0],
  //       points: ['', ''],
  //       color: '#5b9bd5',
  //       style: 'solid',
  //       width: 2,
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'Hj7ttp',
  //       left: 69.35185185185185,
  //       top: 49.21759259259262,
  //       width: 420,
  //       height: 63,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>1.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'FmKMNB',
  //       left: 69.35185185185185,
  //       top: 129.28240740740745,
  //       width: 420,
  //       height: 384,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'rI7ZeO',
  //       left: 510.64814814814815,
  //       top: 49.21759259259262,
  //       width: 420,
  //       height: 63,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>2.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'KspwGc',
  //       left: 510.64814814814815,
  //       top: 129.28240740740745,
  //       width: 420,
  //       height: 384,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'Rx63Jo',
  //       left: 69.35185185185179,
  //       top: 51.71759259259262,
  //       width: 420,
  //       height: 58,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>1.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'ulyuzE',
  //       left: 69.35185185185179,
  //       top: 131.78240740740745,
  //       width: 420,
  //       height: 129,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'kr35Ca',
  //       left: 510.6481481481481,
  //       top: 51.71759259259262,
  //       width: 420,
  //       height: 58,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>2.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'BNQSpC',
  //       left: 510.6481481481481,
  //       top: 131.78240740740745,
  //       width: 420,
  //       height: 129,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'Vr38Nu',
  //       left: 69.35185185185185,
  //       top: 301.71759259259255,
  //       width: 420,
  //       height: 58,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>3.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'IwKRSu',
  //       left: 69.35185185185185,
  //       top: 381.7824074074074,
  //       width: 420,
  //       height: 129,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: '0Opr1v',
  //       left: 510.64814814814815,
  //       top: 301.71759259259255,
  //       width: 420,
  //       height: 58,
  //       lineHeight: 1.2,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>4.请输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: '4L9Uzz',
  //       left: 510.64814814814815,
  //       top: 381.7824074074074,
  //       width: 420,
  //       height: 129,
  //       content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //   ],
  // },
  // {
  //   id: 'template',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 'GdEGxg',
  //       left: 134.53703703703704,
  //       top: 127.25,
  //       width: 152.77777777777777,
  //       height: 308,
  //       lineHeight: 1.8,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 40px\'>请在此处输入标题</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       wordSpace: 8,
  //       fill: 'rgba(91,155,213,1)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'y5sAfw',
  //       left: 332.8703703703704,
  //       top: 127.25,
  //       width: 532.5925925925926,
  //       height: 50,
  //       content: '<blockquote><p style=\'\'>请在此处输入内容1</p></blockquote>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'VeuocM',
  //       left: 332.8703703703704,
  //       top: 212.0648148148148,
  //       width: 532.5925925925926,
  //       height: 50,
  //       content: '<blockquote><p style=\'\'>请在此处输入内容2</p></blockquote>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'RyFWQe',
  //       left: 332.8703703703704,
  //       top: 296.8796296296296,
  //       width: 532.5925925925926,
  //       height: 50,
  //       content: '<blockquote><p style=\'\'>请在此处输入内容3</p></blockquote>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //     {
  //       type: 'text',
  //       id: 'Q56viI',
  //       left: 332.8703703703704,
  //       top: 381.69444444444446,
  //       width: 532.5925925925926,
  //       height: 50,
  //       content: '<blockquote><p style=\'\'>请在此处输入内容4</p></blockquote>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       fill: 'rgb(242, 242, 242)',
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  // {
  //   id: 'template',
  //   elements: [],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
]