import { Slide } from '@/types/slides'
import {createRandomCode} from '@/utils/common'
export const cupSlides: Slide[] = [
  {
    id: createRandomCode(8),
    elements: [
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 0,
        "top": 95,
        "start": [
          0,
          0
        ],
        "end": [
          960,
          0
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 0,
        "top": 480,
        "start": [
          0,
          0
        ],
        "end": [
          960,
          0
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      }
    ],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  }
]
export const reticuleSlides: Slide[] = [
  {
    id: createRandomCode(8),
    elements: [
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 0,
        "top": 30,
        "start": [
          0,
          0
        ],
        "end": [
          700,
          0
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 20,
        "top": 0,
        "start": [
          0,
          0
        ],
        "end": [
          0,
          444
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 0,
        "top": 390,
        "start": [
          0,
          0
        ],
        "end": [
          700,
          0
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 280,
        "top": 0,
        "start": [
          0,
          0
        ],
        "end": [
          0,
          444
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 360,
        "top": 0,
        "start": [
          0,
          0
        ],
        "end": [
          0,
          444
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
      {
        "type": "line",
        "id": createRandomCode(6),
        "left": 620,
        "top": 0,
        "start": [
          0,
          0
        ],
        "end": [
          0,
          444
        ],
        "points": [
          "",
          ""
        ],
        "color": "#626262",
        "style": "dashed",
        "width": 1,
        "fieldKey": "",
        "fieldValue": "",
        "lock": true
      },
    ],
    background: {
      type: 'solid',
      color: '#ffffff',
    },
  }
]