import router from './router/router'
import {useStore} from './store'
import { message } from 'ant-design-vue'
import { getToken } from '@/utils/auth' // get token from cookie


const whiteList = ['/login','/share','/register','/forgotPassword'] // no redirect whitelist
const store = useStore()

router.beforeEach(async(to, from, next) => {
  // start progress bar


  // determine whether the user has logged in
  const hasToken = getToken()
  // console.log(hasToken)
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
    } else {
        next()
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    }else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
    }
  }
})

router.afterEach(() => {
  // finish progress bar
})