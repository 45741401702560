<template>
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    version="1.1"
  >
    <slot></slot>
  </svg>
</template>

<script lang="ts">
export default {
  name: 'svg-wrapper',
}
</script>